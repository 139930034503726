.switch {
  position: relative;
  display: inline-block;
  width: 40px; //ccv style
  height: 24px; //ccv style
}

.switch-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1vh;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-light-150);
  transition: 0.4s;
  border-radius: 24px; //ccv style
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px; //ccv style
  width: 20px; //ccv style
  left: 2px; //ccv style
  bottom: 2px; //ccv style
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-medium);
}

input:checked + .slider:before {
  transform: translateX(16px); //ccv style
}
