.history-button {
  width: fit-content;
  height: 5vh;
  border-radius: 1vh;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--grey-subtle-028);
  color: var(--grey-medium-600);
  padding: 0 0.8vw;
}

.space {
  margin-left: 1vw;
}

.button-with-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  gap: 0.5vw;
  justify-content: space-between;
  width: fit-content;
  user-select: none;
}

.button-with-icon-row {
  width: 100%;
}

.icon-top {
  align-items: start;
  padding-top: 2vh;
}

.icon-blue i {
  color: var(--primary-medium);
}

.reversed {
  flex-direction: row-reverse;
  margin-right: 2vh;
}

.btn--primary {
  color: var(--white) !important;
}

.btn--primary:disabled {
  color: var(--grey-light-150) !important;
}

.row-style .title {
  width: 40%;
  vertical-align: text-top;
}

.row-style .data {
  width: 60%;
  vertical-align: text-top;
}

.projectinfo .MuiTableCell-root {
  vertical-align: middle;
}

.edit_integration_button {
  position: absolute;
  right: 5vh;
  top: 8vh;
  z-index: 50;
}

.icon-btn-blue {
  color: var(--primary-medium);
}

.url_link {
  text-decoration: underline;
  color: var(--primary-medium);
  cursor: pointer;
}

.permission-save-button {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: var(--base-spacing-double);
}

.update-reason-permissions-buttons {
  display: flex;
  justify-content: end;
  gap: var(--base-spacing);
}

@media screen and (max-width: 900px) {
  .icon-top {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
