.Toastify__toast-container {
     width: fit-content!important;
}

.Toastify__toast-container--top-center {
    top: 1vh!important;
}

.Toastify__toast-body{
    padding: 0vh;
}

.Toastify__toast{
    margin: 0;
    padding: 0;
}

.Toastify__toast .message{
    margin: 0;
    padding: 2vh;
}

.toast-progressbar{
    background: var(--primary-medium);
}