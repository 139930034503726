.center {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
}

.spacing-bottom h4 {
  margin-bottom: var(--base-spacing-oneandaquarter);
}

.spacing-bottom {
  margin-bottom: var(--base-spacing);
}

.spacing-top {
  margin-top: var(--base-spacing-oneandaquarter);
}

.form p {
  margin: 0;
}

.form {
  width: 100%;
  min-height: 70vh;
  height: fit-content;
}

.block {
  width: 80vw;
  background-color: var(--white);
  border-radius: 0.8vh;
  padding: 2vh;
  margin-top: 2vh;
}

.block p {
  margin-top: 0;
}

.form .input[type="text"] {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  max-height: fit-content;
}

.textarea,
textarea {
  display: flex;
  width: 100%;
  padding: 1vh;
  margin-top: 0.5vh;
  border: 0.1vh solid var(--grey-light-150);
  border-radius: 0.4vh;
}

.textarea:focus,
textarea:focus {
  border-color: var(--primary-medium);
  outline: none;
  box-shadow: 0 0 0 4px rgba(0, 159, 223, 0.25);
}
.textarea:disabled,
textarea:disabled {
  color: var(--grey-medium-400);
}

.input input {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

.input--radiobutton {
  display: flex !important;
  align-items: center;
  min-width: fit-content;
  justify-content: start;
  z-index: 9;
}

.input--radiobutton label {
  display: flex;
  margin-right: 3vh;
}

.dropzone {
  width: 100%;
  border-radius: 0.3vh;
  margin-top: 1vh;
  padding-bottom: 3vh;
  padding-top: 2vh;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1vh;
  background: rgba(3, 149, 255, 0.05);
  background-color: white;
  //moet voor het design px zijn
  background-image: repeating-linear-gradient(
      -18deg,
      var(--primary-medium),
      var(--primary-medium) 8px,
      transparent 8px,
      transparent 16px,
      var(--primary-medium) 16px
    ),
    repeating-linear-gradient(
      72deg,
      var(--primary-medium),
      var(--primary-medium) 8px,
      transparent 8px,
      transparent 16px,
      var(--primary-medium) 16px
    ),
    repeating-linear-gradient(
      162deg,
      var(--primary-medium),
      var(--primary-medium) 8px,
      transparent 8px,
      transparent 16px,
      var(--primary-medium) 16px
    ),
    repeating-linear-gradient(
      252deg,
      var(--primary-medium),
      var(--primary-medium) 8px,
      transparent 8px,
      transparent 16px,
      var(--primary-medium) 16px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.dropzone-hover {
  background-color: var(--primary-subtle-transparent);
}

.dropzone-drop-file-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.apk-dropzone {
  background-color: var(--primary-subtle-transparent);
}

.apk-dropzone-hover {
  box-shadow: 0px 0px 0px 4px rgba(3, 149, 255, 0.15);
}

.basic-dropzone-body {
  flex-direction: row;
  width: max-content;
  min-width: 20vw;
  border-style: none;
}

.basic-dropzone-body div {
  width: 100%;
}

.acceptedfile {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 0.5vh;
}

.acceptedfile p {
  display: flex;
  align-items: center;
  gap: 0.5vh;
}

.cancelButton:hover {
  cursor: pointer;
}

.dropzone i {
  color: var(--primary-medium);
  padding-right: 0.5vh;
  padding-left: 0.5vh;
}

.rejectedfile {
  color: var(--danger-medium);
  padding-top: 1vh;
}

form p {
  margin: 0;
  padding: 0;
}

.options {
  padding: 0.5vh;
  padding-left: 0;
  cursor: pointer;
  padding-top: 1vh;
  display: inline-flex;
  align-items: center;
}

.options p {
  margin: 0;
}

.button-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.5vh;
  width: 100%;
  gap: 1vw;
}

.css-r40f8v-MuiTypography-root {
  font-size: 2vh !important;
}

.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: var(--primary-medium) !important;
}

.MuiTooltip-tooltip {
  background-color: var(--grey-medium-600) !important;
  line-height: inherit !important;
  min-width: 25vw !important;
  max-height: 10vw !important;
  overflow-y: auto !important;
}

.MuiTooltip-tooltip p {
  margin: 0;
  padding: 0.5vh;
  color: var(--white);
}

.input-label-spacing span,
.input-label-spacing .tooltip button {
  margin-left: 0.2vh;
}

.tooltip {
  opacity: 1;
  z-index: auto;
  padding-left: 0.2vw;
  font-family: var(--base-font-family);
}

.tooltip__body {
  top: -26px !important;
  left: 3.6vh !important;
  z-index: 200;
}

.tooltip i {
  z-index: 0;
}

.tooltip button {
  margin: 0;
  padding: 0;
  z-index: 0;
}

.tooltip-label {
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative !important;
}

.tooltip-label span {
  padding-left: 0.2vw;
}

.MuiTooltip-tooltipPlacementRight {
  margin: 0 0 0 0.5vh !important;
}

.form .MuiIconButton-root {
  padding: 0 !important;
  padding-left: 0.5vh !important;
}

.MuiIconButton-root:hover {
  background-color: inherit !important;
}

.full-width {
  width: 100%;

  .dropzone-box {
    max-width: 100%;
  }

  .large-dropzone {
    height: 20vh;
  }
}

.medium-grey-bold {
  color: var(--grey-medium-600);
  font-weight: var(--font-weight-bold);
}

.btn--tertiary.btn--small {
  padding-right: 1vh !important;
}

.form-input-spacing {
  margin-top: var(--base-spacing);
}

.label-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.characters-left-bold {
  font-weight: var(--font-weight-bold);
  margin-left: 0.5vh;
}

.no-max-length-padding {
  padding-bottom: var(--base-spacing);
}

.no-info-padding {
  padding-bottom: var(--base-spacing);
}

.radiobuttonList-row {
  display: flex;
  flex-direction: row;
  margin-left: 0.5vh;
}

.radiobuttonList-row label:not(:first-child) {
  margin-left: 2vh;
}

.radiobuttonList-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
  margin-top: var(--base-spacing);
  gap: var(--base-spacing);
}

.input--select label {
  width: 100%;
}

.input--radiobutton {
  margin-top: var(--base-spacing-quarter) !important;
}

.project-form {
  width: 60%;
}

.project-form .input--text {
  width: 50%;
}

.project-form .multi-side-block .text-medium {
  margin-top: 2vh;
}

input[type="text"] {
  margin-top: 0.5vh;
}

.form-spacing {
  margin-bottom: 2vh;
}

.label-with-message {
  display: flex;
  gap: 0.5vh;
}

.dropzone-box {
  max-width: fit-content;
  margin-top: 1vh;
}

.small-dropzone .dropzone {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.checkbox label {
  padding-left: 0;
}

.checkbox {
  width: fit-content;
  margin-top: 0;
}

.large-dropzone {
  min-width: 37vw;
}

.screenshots-block {
  display: flex;
  max-width: fit-content;
  gap: 1vh;
}

.add-extra {
  width: 13vh;
  height: 26vh;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.4vh;
  box-shadow: 0px 1px 2px rgba(48, 52, 59, 0.2),
    0px 4px 20px rgba(48, 52, 59, 0.02);
}

.add-extra i {
  color: var(--primary-medium);
  padding: 0.5vh;
  border: 0.2vh dashed var(--primary-medium);
  border-radius: 100%;
  margin-bottom: 1vh;
}

.accepted-app-icon {
  width: 12vh;
  height: 12vh;
  position: relative;
  border-radius: 1vh;
  margin-bottom: 1vh;
  box-shadow: 0 0.1vh 0.2vh rgba(48, 52, 59, 0.2),
    0 0.4vh 1vh rgba(48, 52, 59, 0.02);
}

.accepted-screenshots {
  width: 13vh;
  height: 26vh;
  max-width: 100%;
}

.large-dropzone {
  height: 20vh;
}

.btn--tertiary.btn--small {
  padding-right: 1vh !important;
}

.form-input-spacing {
  margin-top: var(--base-spacing);
}

.characters-left-bold {
  font-weight: 600;
  margin-left: 0.5vh;
}

.radiobuttonList-row {
  display: flex;
  flex-direction: row;
  margin-left: 0.5vh;
}

.radiobuttonList-row label:not(:first-child) {
  margin-left: 2vh;
}

.radiobuttonList-column {
  display: flex;
  flex-direction: column;
  margin-left: 0.5vh;
  margin-bottom: 2vh;
  gap: 1vh;
}

.input--select label {
  width: 100%;
}

.input--radiobutton {
  margin-top: 1vh !important;
}

.project-form {
  width: 60%;
}

.project-form .input--text {
  width: 50%;
}

.project-form .multi-side-block .text-medium {
  margin-top: 2vh;
}

input[type="text"] {
  margin-top: 0.5vh;
}

.form-spacing {
  margin-bottom: 2vh;
}

.textarea-extra-small {
  min-height: 5vh;
}

.textarea-small {
  min-height: 10vh;
}

.textarea-small-medium {
  min-height: 15vh;
  max-height: 15vh;
  overflow: auto;
}

.textarea-medium {
  min-height: 20vh;
}

.textarea-large {
  min-height: 30vh;
}

.textarea-extra-large {
  height: 40vh;
}

.textarea-infininte {
  min-height: 100%;
  padding-bottom: 4.6vh;
}

.textarea-margin-left {
  margin-left: var(--base-spacing-threequarters);
}

.label-with-message {
  display: flex;
  gap: 0.5vh;
}

.dropzone-box {
  max-width: fit-content;
  margin-top: 1vh;
}

.small-dropzone .dropzone {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.checkbox label {
  padding-left: 0;
}

.checkbox {
  width: fit-content;
  margin-top: 0;
}

.large-dropzone {
  min-width: 37vw;
}

.screenshots-block {
  display: flex;
  max-width: fit-content;
  gap: 1vh;
}

.add-extra {
  width: 13vh;
  height: 26vh;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.4vh;
  box-shadow: 0px 1px 2px rgba(48, 52, 59, 0.2),
    0px 4px 20px rgba(48, 52, 59, 0.02);
}

.add-extra i {
  color: var(--primary-medium);
  padding: 0.5vh;
  border: 0.2vh dashed var(--primary-medium);
  border-radius: 100%;
  margin-bottom: 1vh;
}

.accepted-app-icon {
  width: 12vh;
  height: 12vh;
  position: relative;
  border-radius: 1vh;
  margin-bottom: 1vh;
  box-shadow: 0 0.1vh 0.2vh rgba(48, 52, 59, 0.2),
    0 0.4vh 1vh rgba(48, 52, 59, 0.02);
}

.accepted-screenshots {
  width: 13vh;
  height: 26vh;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.4vh;
  filter: drop-shadow(0 0.01vh 0.1vh rgba(48, 52, 59, 0.2))
    drop-shadow(0 0.1vh 1vh rgba(48, 52, 59, 0.02));
}

.screenshots-detail {
  width: 100%;
  height: fit-content;
  padding: 1vh;
  flex-direction: row;
  display: flex;
  border-radius: 0.4vh;
  gap: 1vh;
}

.row-style .screenshots-detail {
  padding: 0.5vh 0.5vh 0.5vh 0;
}

.image-spacing {
  margin-top: var(--base-spacing-half);
}

.one-side-block .accepted-screenshots {
  width: 12vh;
  height: 19vh;
}

.dropzone-edit {
  position: absolute;
  opacity: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.dropzone-edit-icon {
  justify-content: center;
}

.dropzone-edit:hover {
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  cursor: pointer;
}

.dropzone-edit i {
  color: var(--white);
}

.dropzone-delete {
  position: absolute;
  top: 0;
  right: 0;
}

.dropzone-drag {
  opacity: 1;
  border: 0.1vh solid var(--primary-medium);
  box-shadow: 0px 0px 0px 4px rgba(3, 149, 255, 0.15);
}

.dropzone-input p {
  display: flex;
  align-items: center;
  gap: 0.2vw;
}

.screenshots-block {
  padding: 1vh;
  padding-left: 1vh;
  overflow: auto;
}

.result-text {
  padding-bottom: 2vh;
}

.ecr-block {
  align-items: center;
}

.input--select__wrapper {
  margin-top: 0.5vh;
}

.option-with-extra-text {
  gap: 0.1vw;
}

.radio-button-with-icon {
  display: flex;
}

.option-with-extra-text p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.functional-testing-info .left p {
  padding-bottom: 2vh;
}

.two-column-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: var(--no-spacing);
  padding-top: var(--base-spacing-half);
}

.two-column-container .permission_input {
  padding-bottom: var(--base-spacing-oneandaquarter);
  width: 49%;
}

.result_labels {
  display: flex;
  gap: var(--base-spacing-half);
}

.implied-permission-label{
  display: flex;
  gap: var(--base-spacing-quarter);
  align-items: baseline;
}

.ccv-spinner.lottie-small {
  width: auto;
  height: var(--base-spacing);
  padding-top: var(--base-spacing-half);

  div {
    width: var(--base-spacing-double);
    padding-top: 0;
    padding-left: var(--base-spacing-half);
  }
} 

.createRelease {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--base-spacing);
  padding-top: var(--base-spacing);
}

.createRelease .progress {
  width: 50%;
  height: var(--base-spacing-half);
  margin: 0;
}

.createRelease .bg-progress-bar {
  background-color: var(--primary-medium);
}
.no-resize-textarea {
  resize: none;
}

.loading-container {
  width: 100%;
}

.validation-result {
  margin-top: var(--base-spacing);

  .validation-error-label p {
    margin-bottom: var(--base-spacing-half);
  }

  .validation-error-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .message {
    margin-bottom: 1vh;
  }

  .validation-error-link .link-button {
    text-decoration: underline;
    color: var(--danger-medium);
  }

  i {
    margin-right: var(--base-spacing-half);
  }

  .validation-call-failed-button .btn--secondary {
    color: var(--warning-medium);
    border-color: var(--warning-medium);
  }
  .dropzone-delete {
    position: absolute;
    top: 0;
    right: 0;
  }

  .dropzone-drag {
    opacity: 1;
    border: 0.1vh solid var(--primary-medium);
    box-shadow: 0px 0px 0px 4px rgba(3, 149, 255, 0.15);
  }

  .dropzone-input p {
    display: flex;
    align-items: center;
    gap: 0.2vw;
  }

  .screenshots-block {
    padding: 1vh;
    padding-left: 1vh;
    overflow: auto;
  }

  .result-text {
    padding-bottom: 2vh;
  }

  .ecr-block {
    align-items: center;
  }

  .input--select__wrapper {
    margin-top: 0.5vh;
  }

  .option-with-extra-text {
    gap: 0.1vw;
  }

  .radio-button-with-icon {
    display: flex;
  }

  .option-with-extra-text p {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .functional-testing-info .left p {
    padding-bottom: 2vh;
  }

  .validation-result {
    margin-top: var(--base-spacing);
    min-height: 10vh;

    .validation-error-label p {
      margin-bottom: var(--base-spacing-half);
    }

    .validation-error-result {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .message {
      margin-bottom: 1vh;
    }

    .validation-error-link .link-button {
      text-decoration: underline;
      color: var(--danger-medium);
    }

    .validation-error-link .link-button:hover {
      text-decoration: none;
    }

    i {
      margin-right: var(--base-spacing-half);
    }

    .validation-call-failed-button .btn--secondary {
      color: var(--warning-medium);
      border-color: var(--warning-medium);
    }
  }

  @media screen and (max-width: 900px) {
    .project-form {
      width: 100%;
    }

    .project-form .right {
      margin-top: 0;
    }

    .image-dropzone {
      margin-bottom: 0vh;
    }

    .large-dropzone {
      width: 75vw;
    }

    .catalogue .multi-side-block {
      gap: 0;
    }

    .ecr-block {
      align-items: normal;
    }

    .screenshots-detail {
      width: 100%;
    }
  }
}
