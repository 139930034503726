.label-status{
    padding: 0.5vh 1.5vh 0.5vh 1.5vh;
    text-transform: uppercase;  
    width: fit-content;
    border-radius: 0.4vh;
    max-height: fit-content;
}

.label-status p{
    margin: 0;
    padding: 0;
    font-weight: var(--font-weight-semi-bold);
} 

.label-status-failed {
    background-color: var(--danger-subtle);
}

.label-status-failed p{
    color: var(--danger-medium);
}

.label-status-success{
    background-color: var(--success-subtle);
}

.label-status-success p{
    color: var(--success-medium);
}

.label-status-inactive{
    background-color: var(--grey-subtle-028-transparent);
}

.label-status-inactive p{
    color: var(--grey-dark-800);
}

.label-status-warning{
    background-color: var(--warning-subtle-transparent);
}

.label-status-warning p{
    color: var(--warning-medium);
}

.label-status-ongoing{
    background-color: var(--primary-subtle-transparent);
}

.label-status-ongoing p{
    color: var(--primary-medium);
}

.spacing{
    margin-left: 0.5vw;
}

.selectable{
    cursor: pointer;
}