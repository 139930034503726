.catalogue {
  background-color: var(--white);
  padding: 2vh;
  border-radius: 0.8vh;
}

.catalogue p {
  padding-top: 0vh;
  margin: 0;
}

.catalogue-body p {
  padding-top: 0;
}

.catalogue-wrapper {
  border-radius: 0.4vh;
  border-left: 0.3vh solid var(--grey-light-150);
  margin-top: 2vh;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(48, 52, 59, 0.2),
    0px 4px 20px rgba(48, 52, 59, 0.02);
}

.documentation-wrapper {
  border-left: 0.15vh solid var(--grey-light-150);
  margin-top: 0.5vh;
}

.documentation-body {
  padding-left: 2vh;
  padding-bottom: 1vh;
  padding-right: 2vh;
}

.documentation-body p {
  padding-bottom: 0.5vh;
}

.section-dropdown {
  padding-left: 1vh;
  border-left: 0.1vh solid var(--grey-light-150);
}

.catalogue-wrapper button {
  margin-top: 0;
  margin-left: 1vw;
}

.catalogue-wrapper h4 {
  margin-bottom: 0;
}

.catalogue-block {
  display: flex;
  align-items: center;
  border-top: 0.1vh solid var(--grey-light-150);
  margin-bottom: 2vh;
  padding-top: 2vh;
}

.catalogue-block p {
  width: 100%;
}

.catalogue-block:first-of-type {
  border: none;
  margin-top: 0;
}

.catalogue input[type="checkbox"] {
  min-width: 2vh;
  min-height: 2vh;
  margin-right: 1vh;
  margin-top: 0;
  transition: color 0.2s ease;
  cursor: pointer;
}

.catalogue input[type="checkbox"]:hover {
  background-color: var(--grey-light-150);
}

.executed-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
}

.catalogue-block label {
  cursor: pointer;
}

.catalogue-wrapper p {
  display: inline-flex;
}

.catalogue-wrapper label p {
  min-width: max-content;
}

.catalogue-block label input {
  margin-top: 1vh;
}

.catalogue-text {
  border-right: 0.1vh solid var(--grey-light-150);
  margin-right: 4vw;
  padding-right: 4vw;
  width: 60vw;
}

.catalogue-text p {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.requirement-test-contract {
  margin-top: 3vh;
}

.requirement-test-contract .executed-checkbox {
  padding-left: 3.3vh;
}

.requirement-test-contract .catalogue-text {
  width: 60vw;
  border: none;
  margin-right: 4vw;
  padding-right: 4vw;
}

.requirement-test-contract .catalogue-text p {
  margin: 0;
}

.requirement-test-contract .catalogue-block {
  justify-content: start;
  padding-top: 0;
}

.catalogue-heading {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding-left: 3vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-right: 2vh;
}

.catalogue-tooltip {
  display: flex;
}

.catalogue-tooltip button {
  margin-left: 0;
}

.documentation-heading {
  padding-left: 1vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-right: 0.5vh;
}

.catalogue-heading p {
  margin: 0;
  height: 100%;
}

.catalogue-tooltip {
  display: flex;
  align-items: center;
  gap: 0.2vw;
}

.MuiIcon-root {
  width: inherit !important;
  height: inherit !important;
  font-size: inherit !important;
}

.catalogue-heading:hover {
  cursor: pointer;
}

.catalogue-heading-result {
  padding-left: 3vh;
  padding-top: 2vh;
}

.catalogue-heading-result .catalogue-body {
  padding-left: 0vh;
}

.clickable {
  cursor: pointer;
}

.catalogue-body .radiobuttonList-column {
  margin-left: 0;
}

.right .radiobuttonList-column {
  padding-left: var(--base-spacing);
}

.catalogue-open {
  padding-bottom: 0;
}

.catalogue-body {
  justify-content: space-between;
  padding-left: 3vh;
  padding-bottom: 0.5vh;
  padding-right: 3vh;
  margin-bottom: 1vh;
}

.completed {
  color: var(--success-medium);
  margin-right: 2vh;
  display: inline-flex;
}

.completed i {
  margin-right: 1vh;
}

.completed-border {
  border-color: var(--success-medium);
}

.contract p {
  width: 78vw;
}

.contract:hover {
  cursor: pointer;
}

.catalogue-link {
  color: var(--primary-medium);
  text-decoration: underline;
}

.catalogue-link:hover {
  color: var(--primary-medium);
}

.catalogue-list li {
  list-style-type: disc;
  margin-left: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.catalogue-list li p {
  margin: 0;
}

.catalogue-block input[type="text"] {
  width: 25vw;
  cursor: auto;
}

.catalogue-block .input p {
  margin: 0;
}

.catalogue-checkbox {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 0;
  min-height: 2vh;
  padding-top: 1vh;
  width: fit-content;
}

.catalogue-checkbox p {
  margin: 0;
  cursor: pointer;
}

.two-row-checkbox {
  column-count: 2;
  width: 40vw;
}

.checkbox-list {
  margin-bottom: 1vh;
  margin-top: 1vh;
}

.checkbox-list span {
  margin-bottom: 2vh;
}

.accordion-wrapper .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotatex(0.5turn);
}

.projectinfo {
  padding-bottom: 2vh;
  background-color: white;
  border-radius: 0.8vh;
}

.projectinfo-accordion {
  padding: 0;
  box-shadow: none !important;
  border-radius: 0.8vh !important;
  padding-right: 0;
}

.projectinfo-accordion .MuiAccordionSummary-expandIconWrapper {
  width: 4%;
  display: flex;
  justify-content: right;
  margin-top: 3vh;
}

.projectinfo-accordion-header div {
  gap: 2vw;
}

.projectinfo-accordion-body {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  margin-right: 4%;
}

.projectinfo-accordion .MuiAccordionDetails-root {
  padding-top: 0;
}

.projectinfo-accordion .MuiAccordionSummary-content {
  margin-bottom: 0;
}

.projectinfo-accordion .MuiAccordionDetails-root {
  padding-bottom: 0;
}

.documentation-accordion {
  border-radius: 0.8vh;
  background: var(--light-greys-white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(48, 52, 59, 0.02),
    0px 1px 2px 0px rgba(48, 52, 59, 0.2);
  border-top: 0.1vh solid var(--grey-light-150);
  border-left: 0.1vh solid var(--grey-light-150);
  margin: 0 !important;
}

.documentation-accordion .MuiAccordionSummary-expandIconWrapper {
  padding-left: 1vh;
}

.documentation-accordion .MuiAccordionSummary-content {
  border-right: 0.1vh solid black;
  margin: 0;
}

.accordion-wrapper i {
  margin-right: 0.5vh !important;
}

.documentation-accordion .MuiAccordionDetails-root {
  padding: 0vh 2vh;
  padding-bottom: 1vh;
}

.documentation-accordion .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.documentation-accordion .MuiAccordionSummary-root.Mui-expanded {
  min-height: 5vh !important;
  padding-right: 0.5vh;
}

.documentation-accordion .MuiAccordionSummary-root {
  display: flex;
  padding-right: 0.5vh;
  min-height: auto;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.securityscan-info-body {
  max-height: 90%;
  overflow: hidden;
  padding: var(--base-spacing-half);
}

.securityscan-feedback-accordion {
  margin-bottom: var(--base-spacing-half);
  background-color: var(--grey-subtle-028) !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2) !important;
  padding: var(--base-spacing-half) var(--base-spacing) !important;
  position: static !important;
}

.full-feedback-accordion-container {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: var(--base-spacing-half);
}

.securityscan-feedback-accordion .MuiAccordionSummary-expandIconWrapper {
  padding-left: 1vh;
}

.securityscan-feedback-accordion .MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.securityscan-feedback-accordion .MuiAccordionDetails-root {
  padding: 0 var(--base-spacing) var(--base-spacing-half) var(--base-spacing);
}

.securityscan-feedback-accordion .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.securityscan-feedback-accordion.Mui-expanded {
  margin-bottom: var(--base-spacing-half) !important;
  margin-top: 0 !important;
}

.integration-catalogue .multi-side-block {
  width: 100%;
  padding-bottom: 0.2vh;
}

.integration-catalogue .left,
.integration-catalogue .right {
  min-width: 20vw;
  width: left;
}

.securityscan-feedback-details {
  width: 100%;
  height: fit-content;
  max-height: 15vh;
  overflow-y: auto;
  word-break: break-word;
}
