.dialog {
  padding: 1vh;
}

.dialog h2 {
  padding: 0;
}

.dialog-body {
  margin-top: 1vh;
}

.dialog p,
.MuiDialogContent-root {
  padding: 0 !important;
  overflow-y: visible !important;
}

.dialog .MuiIconButton-root {
  right: 1vh;
  top: 1vh;
  position: absolute !important;
}

.scanner-malware-result p {
  margin-right: 1vh;
}

.dialog .MuiPaper-root {
  padding: 2vh 2vh 2vh 2vh;
}

.dialog .material-icons {
  width: 3vh;
  height: 3vh;
  position: absolute;
  right: 2vh;
  top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  color: var(--grey-medium-400);
}

.dialog .material-icons:hover {
  cursor: pointer;
  border-radius: 100%;
  color: var(--grey-medium-600);
}

.dialog .center {
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
}

.input-block-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: var(--base-spacing-oneandaquarter);
  margin-bottom: var(--base-spacing-oneandaquarter);
}

.input-block-checkbox .checkbox {
  margin-top: 0.5vh;
}

.MuiDialogContentText-root {
  padding-left: 0;
}

.MuiDialog-paperWidthSm {
  min-width: 45vw;
  min-height: fit-content;
}

.richtextbox-label {
  padding-bottom: 1vh;
}

.richtextbox-wrapper {
  padding: 0;
  border: 0.1vh solid var(--grey-light-150);
  border-radius: 0.4vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  word-break: break-word;
}

.richtextbox-editor {
  padding: 1vh;
  min-height: 13vh;
  max-height: 13vh;
}

.richtextbox-editor-large {
  min-height: 25vh;
  max-height: 25vh;
}

.richtextbox-toolbar {
  margin: 0 !important;
}

.public-DraftStyleDefault-block,
.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.dialog .form-inline {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 2vh;
}

.dialog-right {
  max-width: 20vw;
  padding: 0;
  margin: 0;
}
.dialog-left {
  width: 20vw;
  padding: 0;
  margin: 0;
}

.dialog .dropzone-box {
  width: fit-content;
  width: 100%;
  height: auto;
  align-items: center;
}

.dialog .dropzone-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdw-option-wrapper {
  border: none !important;
  background: none;
}

.rdw-option-wrapper:hover {
  box-shadow: none !important;
}

.rdw-option-active {
  box-shadow: none !important;
}

.feedback-container {
  background-color: var(--grey-subtle-028);
  padding: 2vh;
  margin: 0;
  border-radius: 0.4vh;
}

.feedback-container p {
  overflow: overlay;
  word-break: break-word;
}

.feedback-container ul li {
  list-style: disc;
}

.feedback-container ul {
  padding: 0;
  padding-left: 3vh;
}

.feedback-response-container {
  padding-top: var(--base-spacing-half);
  margin-top: auto;
}

.status-after-feedback {
  margin-top: auto;
}

.feedback-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5vh;
}

.scanner-malware-result {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-after-feedback p {
  margin: var(--base-spacing-half) 0;
}

.scanner-malware-result i {
  margin-right: 0.5vh;
}

.link-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vh;
  cursor: pointer;
}

.report-download {
  background-color: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.4vh;
  padding: 0.5vh;
  margin-top: 1vh;
}

.report-download-tekst p {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.report-download-tekst i {
  margin-right: 0.5vw;
}

.report-download:hover {
  cursor: pointer;
}

.update-dialog-radio {
  display: flex;
  flex-direction: column;
  margin-top: var(--base-spacing-quarter);
  gap: var(--base-spacing-quarter);
}

.update-dialog-radio label {
  z-index: -1;
}

.update-dialog-radio label div {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-dialog-radio .input--radiobutton div:after {
  top: inherit;
}

.MuiTypography-body1 {
  font-family: inherit !important;
}

.dialog-contract .input--checkbox {
  padding-top: 1vh;
}

.message-block .input--checkbox,
.message-block .dialog-contract {
  padding-top: 0;
}

.dialog-contract span {
  line-height: var(--base-line-height);
}

.dialog-contract div::before,
.dialog-contract div::after {
  margin-top: 0.2vh;
}

.dialog-signer {
  min-height: 20vh;
}

.dialog-signer .input {
  margin-bottom: 0.5vh;
}

.loading-dialog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2vh;
  text-align: center;
}

.loading-dialog div {
  height: 10vh;
}

.loading-dialog .loading-dialog-error-text {
  height: fit-content;
}

.loading-dialog-error i {
  color: var(--danger-medium);
  padding-bottom: 1.5vh;
}

.radio-button-text {
  display: flex;
  flex-direction: row;
  gap: var(--base-spacing-half);
  align-items: center;
}

//icon 24px / main.css
.radio-button-tooltip {
  padding-left: 53px;
}

.permissions-updated-label {
  padding-bottom: var(--base-spacing-half);
}
