.empty .center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.empty{
    background-color: var(--white);
    padding: 3vh;
    display: flex;
    justify-content: start;
    flex-direction: column;
}


