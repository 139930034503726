h4 {
  padding-left: 0;
}

.download-link.no-wrap {
  white-space: nowrap;
  text-decoration: underline;
}

.info {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: fit-content;
  gap: 3vh;
}

.previous-result-securityscan {
  display: flex;
  align-items: center;
  gap: var(--base-spacing-half);
}

.message-block {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message-block-label-with-icon {
  display: flex;
  gap: var(--base-spacing-half);
}

.message-block-row {
  flex-direction: row;
  align-items: center;
}

.message-block .dialog-contract {
  min-width: 12vw;
  margin-top: 0;
  padding-top: 1vh;
  margin-left: 1vh;
  justify-content: right;
}

.message-block .btn--small {
  margin-top: 2vh;
}

.message-block-text {
  display: flex;
  flex-direction: row;
}

.message-block-text i {
  margin-right: 1vw;
}

.message-block.message-block-permissions {
  display: block;
  width: 100%;
}

.reason-name-header{
  display: flex;
  justify-content: space-between;
}

.edit_reason_permission_button{
  cursor: pointer;
}

.message-block-text-neptune-found {
  padding-top: var(--base-spacing);
}

.message-block.employee-message-block {
  padding: 0 0 var(--base-spacing-double) 0;
}

.neptune-found-text {
  font-weight: var(--font-weight-semi-bold);
}

.failed-button {
  border-color: var(--danger-medium) !important;
  color: var(--danger-medium) !important;
}

.more-info-button {
  border-color: var(--warning-medium) !important;
  color: var(--warning-medium) !important;
}

.button-row {
  display: flex;
  gap: var(--base-spacing);
  align-items: baseline;
}

.message-block .button-row .btn {
  flex-shrink: 0;
}

.info-block {
  width: 100%;
  border-radius: 1vh;
  background-color: var(--white);
  margin-top: 3vh;
  padding: 2vh;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
}

.info-block-full-page {
  width: 100%;
  border-radius: 1vh;
  background-color: var(--white);
  padding: 2vh;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
}

.general-info-block {
  width: 100%;
  border-radius: 1vh;
  background-color: var(--white);
  padding: 2vh;
  max-height: fit-content;
  display: flex;
  gap: 0;
}

.general-info-block .left {
  margin-right: 2vw;
}

.empty-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: fit-content;
  gap: 1vh;
  padding-top: var(--base-spacing);
}

.empty-block .material-icons-outlined {
  font-size: 7vh;
  color: var(--color-icon-secondary);
}

.smarttable-spinner .empty-block {
  height: 20vh;
}

.dark p {
  color: var(--grey-dark-800);
  font-weight: 400;
}

.buttonblock-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 2vh;
  gap: 1vh;
}

.show-history-btn {
  bottom: 3vh;
  z-index: 20;
  display: flex;
  width: 38vw;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.history-block {
  max-height: 100%;
  position: relative;
}

.documentation {
  margin-top: 1vh;
  padding-right: 1vh;
  padding-bottom: 1vh;
  width: 100%;
  max-height: 25vh;
  overflow: auto;
}

.documentation a {
  text-decoration: none;
  word-break: break-all;
  width: 100%;
}

.documentation a:hover {
  text-decoration: none;
  cursor: pointer;
}

.documentation p {
  display: flex;
  margin: 0;
  font-family: var(--base-font-family);
  color: var(--grey-dark-800);
}

.documentation i {
  margin-right: 1vh;
}

.content-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.status-button {
  position: absolute;
  top: 20vh;
  right: 4vh;
}

.release-notes-header {
  display: flex;
  justify-content: space-between;
}

.release-notes-edit {
  width: 100%;
  display: flex;
  justify-content: end;
}

.progress-block {
  width: 100%;
  margin-bottom: 2vh;
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.progress-block .MuiSvgIcon-root {
  font-size: inherit;
}

.progress-block .message {
  width: fit-content;
  max-width: 40vw;
  margin-top: 2vh;
}

.message-block-left {
  align-items: start;
}

.message-block-left .message {
  max-width: fit-content;
}

.message-block-full {
  display: flex;
  align-items: start;
  width: 100%;
}

.release-notes-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  margin-left: var(--base-spacing-threequarters);
  margin-right: 1vw;
  margin-bottom: 1vw;
}

.release-notes-block-app-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1vw;
}

.release-notes-button-block {
  display: flex;
  justify-content: center;
}

.release-notes-button-block p {
  min-width: fit-content;
}

.align-top {
  align-items: flex-start;
}

.release-notes-block p {
  margin-top: 0;
}

.detail-spacing {
  margin-top: 2vh;
}

.fadeout {
  position: absolute;
  height: 12vh;
  width: 95%;
  overflow: hidden;
  bottom: 0vh;
  z-index: 19;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%
  );
  background-image: -moz-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%
  );
  background-image: -o-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%
  );
  background-image: -ms-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%
  );
}

.icon-text p {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 0.5vh;
  padding-bottom: 1vh;
}

.info-box {
  background-color: var(--grey-subtle-028);
  border-radius: var(--base-border-radius);
  padding: var(--base-spacing) var(--base-spacing-oneandaquarter)
    var(--base-spacing) var(--base-spacing-oneandaquarter);
  margin-bottom: var(--base-spacing);
}

.info-box-heading {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }

  .progress-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 80vw;
  }

  .MuiBox-root {
    padding-bottom: 5vw;
  }

  .status-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 100%;
  }

  .progress-block .stepper-block {
    width: 80vw;
    margin: 0;
  }

  .progress-block .message {
    max-width: 80vw !important;
  }

  .show-history-btn {
    width: 80vw;
  }

  .info {
    gap: 0;
  }
}
