.slider-container{
    min-width: 0;
    min-height: 0;
}

.slider-container img{
    width: 12vh;
    height: 22vh;
}

.slick-element{
    justify-content: center;
    display: flex!important;
}

.slick-slider{
    display: flex;
    align-items: center;
}

.slick-slide{
    padding: 0.5vh;
}

.slick-track{
    display: inline-flex;
    min-width: 20vw!important;
}

.slick-list{
    max-width: 20vw;
    overflow: hidden;
}

.slick-nav-button{
    cursor: pointer;
    user-select: none;
}

.slick-disabled{
    color: var(--grey-light-150);
    cursor: default;
}

.slider-container .screenshots-detail{
    overflow-x: initial;
}

