.history-block-table{
    overflow: hidden;
}

.record-flex-container {
    padding: 1vh;
    display: flex;
    gap: 1vw;
    justify-content: space-between;
    width: 100%;
}

.record-title {
    border-bottom: 0.2vh solid var(--grey-light-150);
    padding: 1vh;
}

.record{
    border-bottom: 0.2vh solid var(--color-table-secondary-border);
}

.record p{
    margin: 0;
}

.cell  p{
    width: fit-content;
}

.record .cell{
    padding-top: 1vh;
    padding-bottom: 1vh;
    display: flex;
    justify-content: start;
    align-items: center;
}

.cell:last-child{
    margin-right: 0;
}

.timestamp {
    min-width: fit-content;
    max-width: fit-content;
}

.version{
    overflow-wrap: break-word;
   min-width: 15%;
   width: fit-content;
   max-width: 15%;
}

.break-word{
    word-break: break-all;
}

.version:last-child{
    word-break: break-all;
}

.user {
    max-width: fit-content;
}

.history-info {
    display: grid!important;
    grid-template-columns: 5fr 1fr 5fr;
    gap: 0.5vw;
    width: 100%;
}

.one-column{
    grid-template-columns: 1fr;
}

.history-wrapper{
    background-color: var(--white);
    height: fit-content;
    width: 80vw;
    padding: 4vh;
    margin-top: 3vh;
    border-radius: 2vh;
}

.feedback-link{
    text-align: end;
    display: flex;
    justify-content: end;
    width: 100%;
}

.feedback-link p{
    cursor: pointer;
}

.shrinked{
    max-height: 37vh;

    .user{
        display: none;
    }
}
