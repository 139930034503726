.MuiTableCell-root {
  font-family: inherit !important;
  font-weight: inherit !important;
  border-bottom: var(--base-border) !important;
  border-color: var(--color-table-secondary-border) !important;
  padding: var(--base-spacing) var(--base-spacing-threequarters)
    var(--base-spacing) var(--base-spacing-threequarters) !important;
  max-height: 3vh;
}

.download-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-medium);
  width: 100%;
  cursor: pointer;
  gap: 0.3vh;
}

.download-icon {
  color: var(--primary-medium);
  cursor: pointer;
  font-weight: 370;
  text-align: right;
}

.download-icon-link {
  color: var(--primary-medium);
  cursor: pointer;
  font-weight: 370;
}

.download-icon-link:hover {
  text-decoration: underline;
  color: var(--primary-medium);
}

.download-icon-link i {
  color: var(--black);
}

.smarttable {
  padding-left: 1vw;
  padding-right: 1vw;
}

.ccv-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccv-spinner div {
  width: 30vw;
  height: 15vh;
  padding-top: 2vh;
}

.row-style .data {
  word-break: break-word;
  width: 100% !important;
}

.row-table-security-scan {
  gap: 1vh;
  padding-bottom: 0;
  justify-content: space-between;
}

.assigned-tasks-table {
  border-left: 0.5vh solid var(--primary-light);
}

.button_icons {
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 0.5vw;
}

.row-clickable:hover {
  background-color: #f5f5f5;
  cursor: pointer;
  transition-duration: 0.2s, 0.2s;
  border: 0px solid #f5f5f5;
}

.basic-table tbody .MuiTableRow-root {
  border-bottom: 0.2vh solid #f5f5f5;
}

.basic-table .MuiTableRow-root {
  border-bottom: 0.2vh solid var(--grey-light-150);
}

.border-disabled td {
  border: none;
}

.label-with-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5vw;

  .download-link {
    justify-content: start;
    width: auto;
  }

  button {
    display: flex;
    flex-shrink: 0;
    width: fit-content;
    min-width: 10vw;
    padding: 0 var(--base-spacing-double);
    align-items: center;
  }

  .link-button {
    width: max-content;
  }

  .input--text {
    width: 100%;
  }

  .rowtable_input_form {
    width: 100%;
    .dropzone {
      min-width: 50%;
    }
  }
}

.scan-result-label-with-icon {
  display: flex;
  gap: 0.5vh;
}

.MuiList-root li {
  font-size: var(--base-font-size);
  font-family: var(--base-font-family);
}

.MuiInput-input input[text] {
  margin-top: 0;
}

.validation-checklist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.checklist-row {
  width: 50%;
  display: flex;
  justify-content: space-between;
  gap: var(--base-spacing-double);
  align-items: start;
  border-bottom: var(--base-border);
  border-color: var(--color-table-secondary-border);
  gap: var(--base-spacing-half);
  padding: var(--base-spacing) var(--base-spacing-double) var(--base-spacing)
    var(--base-spacing);

  p {
    width: 85%;
  }
}

.extra-info-securityscan-feedback-container{
  padding-top: var(--base-spacing);
}
