.tab-navigator{
    width: 100%;
}

form .tab-navigator{
    margin-top: 3vh;
}

.tabs{
    display: flex;
    border: 0.1vh solid var(--grey-light-150);
    width: fit-content;
    border-top-left-radius: 0.5vh;
    border-top-right-radius: 0.5vh;
    border-bottom: none;
    z-index: 30;
}

.tabs span{
    padding: 1vh 3vh 1vh 3vh;
    background-color: var(--grey-subtle-028);
    border-right: 0.1vh solid var(--grey-light-150);
    border-bottom: 0.1vh solid var(--grey-light-150);
    cursor: pointer;
}

.tabs span:first-child{
    border-top-left-radius: 0.5vh;
}

.tabs span:last-child{
    border-top-right-radius: 0.5vh;
    border-right: none;
}

.tabs .active{
    background-color: var(--white);
    border-bottom: none;
}

.tabs .disabled{
    cursor: not-allowed;
    color: var(--grey-light-150);
}

.tabs .active:hover{
    cursor: pointer;
}

.tab-navigator .navigator-body{
    background-color: var(--white);
    padding: 1.5vw;
    border-radius: 0 1vh 1vh 1vh;
}

.tab-navigator .block{
    padding: 0;
}

.tab-navigator .block{
    margin: 0;
    width: 100%;
}

.tab-navigator .info-block{
    margin-top: 0;
    padding: 0;
}

.tab-navigator .catalogue{
    padding: 0;
}

.tab-navigator .button-block{
    margin-top: 0vh;
}

.spread{
    justify-content: space-between;
}

.create-button{
    position: absolute;
    right: 3vw;
    top: 17vh;
    z-index: 20;
    width: fit-content;
}

.dont-show{
    display: none;
}

@media screen and (max-width: 900px) {
    .create-button{
        position: relative;
        right: 0;
        top: 0;
        z-index: 0;
        margin-bottom: 2vh;
        justify-content: center;
        width: 100%;
    }
  }

