@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html {
  //basic pixels rem uses
  font-size: 16px;
}

.initial-loading-page {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.webcontent {
  max-width: 100vw !important;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: var(--grey-subtle-028);
  display: grid;
  grid-template-columns: 15vw 85vw;
  grid-template-areas: "sidebar main";
}

.sidebar {
  box-sizing: border-box;
  grid-area: sidebar;
  background-color: var(--white);
  //px because this is the height op the ccv header
  margin-top: 58px;
}

.sidebar .active {
  overflow: hidden;
  color: var(--primary-medium);
  border-color: var(--primary-medium);
  background: var(--light-primary-primary-subtle, #f2faff);
}

.fallback-spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar i {
  margin-right: 0.9vw;
  color: var(--grey-dark-800);
}

.active p,
.active i {
  color: var(--primary-medium);
  overflow: hidden;
}

.sidebar a {
  border-left: 4px solid;
  border-color: var(--white);
  padding: 2vh 0 2vh 2vh;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  overflow: hidden;
}

.sidebar a:hover {
  cursor: pointer;
  color: var(--primary-medium);
  padding-left: 3vh;
  transition: 0.12s padding ease-in-out;
}

.sidebar ul {
  padding-left: 0;
  position: fixed;
  width: 15vw;
  list-style: none;
}

.main {
  max-height: fit-content;
  grid-area: main;
  margin-top: 15vh;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-bottom: 2vh;
}

.header {
  min-height: 8.5vh;
  top: unset !important;
  padding-left: 2vw;
  padding-bottom: 2vh;
  max-width: 85vw;
  padding-top: 1vh;
  margin-left: 15vw;
  z-index: 50 !important;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  background: var(--grey-subtle-028) !important;
  border: none !important;
  //px because this is the height op the ccv header
  margin-top: 58px;
}

.header h1 {
  font-style: normal;
  margin-top: 1.5vh;
}

.pageTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.pageTitle h2 {
  padding-top: 2vh;
}

.pageTitle p {
  padding-top: 1vh;
  padding-right: 4vh;
  margin-left: auto;
}

.back-btn {
  margin-top: 2vh;
  margin-right: 0.5vw;
}

.pageTitle i {
  color: var(--black);
}

.project_info-popup-icon i {
  color: var(--grey-medium-600);
}

.home {
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-top: -1vh;
}

.btn-block {
  display: flex;
  justify-content: end;
  width: 80vw;
}

.floating_button {
  position: absolute;
  top: 9vh;
  right: 2vw;
  z-index: 500;
  width: fit-content;
}

.MuiIconButton-root {
  font-size: inherit !important;
}

.multi-side-block {
  padding-bottom: 1vh;
  display: flex;
  width: 100%;
  gap: 5vw;
}

.one-side-block .right {
  margin-top: 2vh;
}

.one-side-block .right,
.one-side-block .left {
  margin-left: 0;
  width: 100%;
}

.one-side-block .right,
.one-side-block .right .detail-spacing {
  margin-top: 0;
}

.left {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.right {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.functional-testing-info .right {
  padding-bottom: 2vw;
}

label {
  font-weight: var(--base-font-weight);
}

.header-with-edit {
  display: flex;
  justify-content: space-between;
}

.btn--primary:hover {
  color: var(--white) !important;
}

.btn--secondary {
  color: var(--primary-medium) !important;
}

.btn--secondary:hover {
  color: var(--primary-medium);
}

input[type="checkbox"] {
  accent-color: var(--primary-medium);
  border-radius: 50%;
}

.white-block {
  background-color: white;
  padding: 3vh 3vh 2vh 3vh;
  border-radius: 0 1vh 1vh 1vh;
}

.project-info-popup:hover {
  .project-info-block {
    display: flex;
    opacity: 1;
    visibility: visible;
  }

  .icon_button_dropdown {
    transform: rotate(180deg);
  }
}

.icon_button_dropdown {
  transition: transform 0.3s ease-in-out;
}

.project-info-block {
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out;
}

.project-info-popup {
  position: absolute;
  right: 5vh;
  top: 4vh;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: end;

  .project-info-block {
    background-color: white;
    margin-top: 1vh;
    padding: 2vh;
    flex-direction: column;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .right,
    .left {
      width: 18vw;
    }
  }
}

.list-item-project-info {
  margin-top: 1vh;
}

.list-of-checks {
  margin-top: var(--base-spacing-half);
  margin-bottom: var(--base-spacing);
}

.list-of-checks ul {
  padding-left: var(--base-spacing-oneandaquarter);
}

.project-info-popup .button-with-icon,
.project-info-popup p {
  gap: 0;
  padding: 0;
  margin: 0;
}

.unauthorized-error {
  width: 100vw;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 51;
  background-color: white;
}

.default-centre-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-spacing-bottom {
  margin-bottom: var(--base-spacing);
}

.default-spacing-top {
  margin-top: var(--base-spacing-threequarters);
}

@media screen and (max-width: 900px) {
  .multi-side-block {
    flex-direction: column;
    width: 100%;
  }

  .right,
  .left {
    min-width: 100%;
    margin-left: 0;
  }

  .right {
    margin-top: 0vh;
  }

  .gap {
    gap: 0;
  }

  .floating_button {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    justify-content: center;
    margin-top: 1vh;
  }

  .sidebar p {
    display: none;
  }

  .sidebar,
  .sidebar .active {
    width: 8vh;
  }
}
